import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // .post("/api/all_addbinifit", { family_id: this.id })
    fetchServices(ctx, ) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/main_services')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    
    },
    fetchServicesById(ctx, { id }) {
      return new Promise((resolve, reject) => {
      let url=`/api/v1/main_services/${id}/proposal_services`
       
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
     
    },
    
    
  },
}
